import React from "react";
import {
  Box,
  Container,
  Image,
  Tabs,
  TabList,
  Tab,
  SimpleGrid,
} from "@chakra-ui/react";
import MasterLayout from "./master";
import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../components/seo/player_seo";

const BooksLayout = (props) => {
  const { pageContext } = props;
  const { books, currentBoard, boards, className } = pageContext;

  return (
    <MasterLayout>
      <Seo
        title={`${className} Audiobooks ${currentBoard} - SunoKitaab`}
        description={`Listen Audio books of ${className} at SunoKitaab. NCERT Audiobooks, taught by brilliant teachers from all over India covering every education board including CBSE, WBBSE, RBSE, ICSE, UPMSP and more.`}
        bookImage={'https://s3sunokitaab.s3.ap-south-1.amazonaws.com/site-asset/logo.png'}
        headline={`${className} AudioBook Lectures ${currentBoard} - SunoKitaab`}
        url={`https://sunokitaab.com${props.location.pathname}`}
      />
      <Container py={8} maxW={"6xl"}>
        <a href="https://sunokitaab.com/app">
          <Box maxW={"full"}>
            <StaticImage src={"../images/long_width_app_banner.png"} />
          </Box>
        </a>
        <Box width={"full"}>
          <Tabs
            colorScheme={"green"}
            defaultIndex={boards.indexOf(currentBoard)}
          >
            <TabList>
              {boards.map((b) => (
                <Tab
                  fontSize={{
                    lg: "2rem",
                    md: "1.6rem",
                    sm: "1.6rem",
                    base: "md",
                  }}
                  fontWeight={"bold"}
                  onClick={() =>
                    navigate(
                      `/${className
                        .toLowerCase()
                        .split(" ")
                        .join("-")}-${b.toLowerCase()}`
                    )
                  }
                >
                  {b}
                </Tab>
              ))}
            </TabList>
            <SimpleGrid
              py={8}
              columns={{ lg: 3, md: 2, sm: 2, base: 2 }}
              gap={{ lg: 12, md: 10, sm: 4, base: 4 }}
            >
              {books.map((book) => {
                if (
                  book.board.filter((b) => b.name == currentBoard).length > 0
                ) {
                  return (
                    <a
                      href={`/${className
                        .toLowerCase()
                        .split(" ")
                        .join("-")}-${currentBoard.toLowerCase()}/${book.hinglish_name
                          ? book.hinglish_name
                            .toLowerCase()
                            .split(".")
                            .join("")
                            .split(" - ")
                            .join("-")
                            .split(" ")
                            .join("-")
                          : book.name
                            .toLowerCase()
                            .split(".")
                            .join("")
                            .split(" - ")
                            .join("-")
                            .split(" ")
                            .join("-")
                        }`}
                    >
                      <Image
                        width={"full"}
                        src={book.thumbnail}
                        borderRadius={12}
                      />
                    </a>
                  );
                }
              })}
            </SimpleGrid>
          </Tabs>
        </Box>
      </Container>
    </MasterLayout>
  );
};

export default BooksLayout;
